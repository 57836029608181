<template>
  <b-modal
    v-model='show'
    content-class='package-modal rounded-8 product-detail-modal'
    dialog-class='wameed-modal-md'
    no-fade
  >
    <template slot='modal-header-close'>
      <close-icon />
    </template>
    <template slot='modal-title'>
      <div class='d-block text-center'>
        <h3 class='text-reg-18'>
          {{ $t('orders.products.title') }}
        </h3>
      </div>
    </template>


    <div class='body'>


      <div class='product-info'>
        <div class='product-info_data'>

          <!--          <img src="https://backend.talqa.wameedprojects.com/uploads/amirali-mirhashemian-sc5sTPMrVfk-unsplash.jpg"-->
          <!--               alt="">-->
          <img v-if='detail.image && showImage' :src=detail.image :alt=detail.name @error='onError' class='product-img'>
          <div v-else class='product-img'>
                <span>
        {{ trimName[0] }}
               </span>
          </div>
        </div>

        <div class='product-info_data_col'>
          <div class='name'>{{ detail.name }}</div>
          <div class='desc'>{{ detail.description }}</div>
          <div class='option'>
            <check-price
              :price='prodPrice'
              :dis-price='calcDiscount'
            />
            <div class='counter'>
              <div class='counter-btn' @click='plusAction'>
                <o-plus-icon />
              </div>
              <div class='number'>
                {{ form.counter }}
              </div>
              <div class='counter-btn' :class="minusDisabled?'disable':''" @click='minusAction'>
                <o-minus-icon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='divider' v-if='detail.options && detail.options.length>0' />


      <div v-for='(option, index) in detail.options' :key='index'>
        <div class='options-info'>
          <div class='flex'>
            <div class='name'> {{ option.name }}</div>
            <div v-if='option.is_required' class='type badge badge-light-danger badge-pill px-3'>
              {{ $t('orders.products.modal.required') }}
            </div>
            <div v-else class='type badge badge-light-font-sub badge-pill px-3'>
              {{ $t('orders.products.modal.optional') }}
            </div>
          </div>
          <div class='desc'>{{ $t('orders.products.modal.choose-one') }}</div>
        </div>

        <div class='product-options'>
          <label class='custom-input input-bottom-border' v-for='optionItem in option.items' :key='optionItem.id'>
            <input :value='JSON.stringify(optionItem)'
                   :checked='checkOptionInCart(optionItem)'
                   :type="option.max_selected_options===1?'radio':'checkbox'"
                   :name='option.name'
                   @change='(e)=>chooseProductOption(optionItem,option.max_selected_options===1,option.price_calc_mechanism==ProductGroupPricing.override_price)'
            />
            <span class='text-reg-16'>{{ optionItem.name }}</span>
            <span class='ml-auto text-reg-16'>
                <span v-if='optionItem.price==0'>
                {{ $t('common.free') }}
              </span>
              <span v-else>
                {{ formatNumber(optionItem.price) }}
              </span>
            </span>
          </label>
        </div>
        <div class='divider' v-if='detail.options && detail.options.length>0 && index < detail.options.length-1' />
      </div>


    </div>


    <template slot='modal-footer' class='d-none'>
      <wameed-btn
        variant='main'
        size='md'
        :title="cartProduct?$t('common.save_edit'):$t('common.add_to_cart')"
        classes='text-book-18  rounded-12 w-unset px-4 m-0 main-btn'
        block
        @onClick='fillData'
      />
      <wameed-btn
        variant='gray'
        size='md'
        :title="$t('common.cancel')"
        classes='text-book-18  rounded-12 w-unset px-4 m-0 gray-btn'
        :class="cartProduct?'move-right':''"
        block
        @onClick='closeModal'
      />
      <wameed-btn
        v-if='cartProduct && showDelete'
        variant='light-danger'
        size='md'
        :title="$t('common.delete_form_cart')"
        classes='text-book-18  rounded-12 w-unset px-4 m-0 border-0 light-danger-btn'
        block
        @onClick='removeFromCart'
      />
    </template>
  </b-modal>
</template>

<script>
import { WameedBtn, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { mapGetters } from 'vuex';
import CheckPrice from '@/components/checkPrice';
import { ProductGroupPricing } from '@/enums/ProductGroupPricing.enum';


export default {
  components: {
    CheckPrice,
    WameedBtn,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    newOrder: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: true
    },

    orderId: [Number, String],
    cartProduct: {
      type: Object,
      default: null
    }

  },

  data() {
    return {
      options_price: 0,
      new_price: 0,
      form: {
        counter: 1,
        price: 0,
        options: []
      },
      error: {},
      showImage: true
    };
  },
  computed: {
    ProductGroupPricing() {
      return ProductGroupPricing;
    },
    ...mapGetters({
      detail: 'vendor/packages/getProductDetail'
    }),
    prodPrice() {
      if (this.detail && 'price' in this.detail) {
        let price = this.detail.price;
        if (this.new_price > 0) {
          price = this.new_price;
        }
        return (price + this.options_price) * this.form.counter;
      }
      return 0;
    },
    calcDiscount() {
      if (this.prodPrice && this.detail.discount) {
        return this.prodPrice - (this.prodPrice * (this.detail.discount / 100));
      }
      return 0;
    },
    minusDisabled() {
      return this.form.counter <= 1;
    },
    trimName() {
      if (this.detail && 'name' in this.detail) {
        return this.detail.name.toString().trim();
      }
      return '';
    },


    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.options_price = 0;
          this.new_price = 0;

          this.form = {
            counter: 1,
            price: 0,
            options: []
          };
          this.$emit('close');
        }
      }
    }
  },
  watch: {
    cartProduct(newVal) {
      if (newVal != null) {

        this.form.counter = newVal.quantity;

        newVal.options.forEach(option => {
          this.chooseProductOption(option, false, option.price_calc_mechanism);
        });


      } else {
        this.form.counter = 1;
      }
    }
  },
  created() {
    if (this.cartProduct != null) {
      this.form.counter = this.cartProduct.quantity;
    }

  },
  methods: {
    checkOptionInCart(option) {
      if (this.form && this.form.options && this.form.options.length > 0)
        return this.form.options.some(item => item.id == option.id);
      return false;
    },
    chooseProductOption(option, remove_prev = false, override_price = false) {

      if (remove_prev) {
        this.form.options = this.form.options.filter(item => item.option_group_id != option.option_group_id);
      }
      if (this.checkOptionInCart(option)) {
        this.form.options = this.form.options.filter(item => item.id != option.id);
        this.options_price -= option.price;
      } else {
        if (override_price) {
          this.new_price = option.price;
        } else {
          this.options_price += option.price;
        }
        this.form.options.push(option);
      }

    },
    removeFromCart() {
      let time = this.cartProduct.time;
      let order_product_id = 0;
      if (this.cartProduct) {
        time = this.cartProduct.time;
        order_product_id = ('order_product_id' in this.cartProduct) ? this.cartProduct.order_product_id : 0;
      }
      if (this.newOrder) {

        this.$store.dispatch('admin/newOrder/removeFromCart', {
          time: time,
          id: this.cartProduct.id
        });
      } else {

        this.$store.dispatch('vendor/orders/removeFromCart', {
          time: time,
          order_product_id: order_product_id
        });
      }

      this.show = false;
    },

    plusAction() {
      this.form.counter++;
    },
    minusAction() {
      if (this.form.counter > 1) {
        this.form.counter--;
      }

    },
    filterClick(filter) {
      this.form.filter = filter;
    },
    onError() {
      this.showImage = false;
    },

    validateOptions() {
      let success = true;
      let options = this.detail.options;
      if (options && options.length > 0) {
        options.map(option => {
          let selectedOptions = [];
          if (this.form.options) {
            selectedOptions = this.form.options.filter(item => item.option_group_id == option.id);
          }
          if (option.is_required) {
            if (selectedOptions.length == 0) {

              alert('يجب اختيار خيارات' + ' (' + option.name + ') ' + ' ');
              success = false;
            }

          }
          if (selectedOptions.length > option.max_selected_options) {

            alert('تجاوزت الحد الاقصى للخيارات' + ' (' + option.name + ') ' + ' ' + '\n' +
              ' الحد الاقصى هو - ' + option.max_selected_options);
            // alert( + 'تجاوزت الحد الاقصى للخيارات');
            success = false;
          }

          if (selectedOptions.length !== 0 && selectedOptions.length < option.min_selected_options) {

            alert('لم تصل الى الحد الادنى للخيارات' + ' (' + option.name + ') ' + ' ' + '\n' +
              ' الحد الادنى هو - ' + option.min_selected_options);
            // alert( + 'تجاوزت الحد الاقصى للخيارات');
            success = false;
          }
        });

      }

      return success;
    },


    fillData() {
      if (this.validateOptions()) {


        const d = new Date();
        let time = d.getTime();
        let productId = this.detail.id;
        let order_product_id = 0;
        if (this.cartProduct) {
          time = this.cartProduct.time;
          order_product_id = ('order_product_id' in this.cartProduct) ? this.cartProduct.order_product_id : 0;
        }


        let product =
          {
            id: productId,
            time: time,
            image: this.detail.image,
            name: this.detail.name,
            old_price: (this.prodPrice / this.form.counter) + this.calcDiscount,
            price: (this.prodPrice / this.form.counter),
            quantity: this.form.counter,
            options: this.form.options,
            order_product_id: order_product_id,
            total: this.prodPrice
          };

        this.$emit('onSubmit', product);


        this.show = false;
      }
    },
    closeModal() {
      this.show = false;
      this.options_price = 0;
      this.new_price = 0;
      this.form = {
        counter: 1,
        price: 0,
        options: []
      };
    }
  }

};
</script>
